<template>
  <div class="container" ref="container">
    <div class="topimg">
      <div class="toptile">EAP干事核心素养测评</div>
      <div class="toptile2">个人报告</div>

      <div class="usermsg">
        <div class="">工号: {{ userName }}</div>
        <div class="">报告生成时间{{ addTime }}</div>
      </div>
    </div>
    <div class="content">
      <!-- 报告说明 -->
      <section class="report-section">
        <div class="section-title">一、报告说明</div>
        <p class="section-description">非常感谢您完成EAP干事核心素养测评。</p>
        <ol class="section-list">
          <li>
            1.心理测评是了解心理状态的方法之一，其准确性受是否理解题意与认真作答、作答动机等多种因素的影响。在阅读本测评报告时，请结合作答时的状态，并参照日常生活中的实际情况来理解测试结果。
          </li>
          <li>
            2.人的心理状态是动态变化的过程，不要因为1次测评给自己贴上负面标签，可以用理性、积极的眼光看待本次测评。
          </li>
          <li>
            3.测评报告供您初步了解自己当前的心理状态，不能作为临床诊断的依据，如果您对测评结果有疑问或正在受心理问题困扰，请向专业人员寻求建议和帮助。
          </li>
        </ol>
      </section>

      <!-- 测评结果 -->
      <section class="report-section">
        <h2 class="section-title">二、测评结果</h2>
        <article class="result-article">
          <div class="result-item">
            <h2 class="item-title">{{ data0[0].entityName }}</h2>
            <p class="item-description" v-if="data0[0].entityExplain">
              {{ data0[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data1[0].entityAd"></div>
            <h2 class="item-title">{{ data1[0].entityName }}</h2>
            <p class="item-description" v-if="data1[0].entityExplain">
              {{ data1[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data1[0].entityAd"></div>
            <h2 class="item-title">{{ data2[0].entityName }}</h2>
            <p class="item-description" v-if="data2[0].entityExplain">
              {{ data2[0].entityExplain }}
            </p>
          </div>
        </article>

        <article class="result-article">
          <div class="result-item">
            <h2 class="item-title">{{ data3[0].entityName }}</h2>
            <p class="item-description" v-if="data3[0].entityExplain">
              {{ data3[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data3[0].entityAd"></div>
            <h2 class="item-title">{{ data4[0].entityName }}</h2>
            <p class="item-description" v-if="data4[0].entityExplain">
              {{ data4[0].entityExplain }}
            </p>
            <div class="item-suggestions2" v-html="data4[0].entityAd"></div>

            <h2 class="item-title">{{ data9[0].entityName }}</h2>
            <p class="item-description" v-if="data9[0].entityExplain">
              {{ data9[0].entityExplain }}
            </p>
          </div>
        </article>

        <article class="result-article">
          <div class="result-item">
            <h2 class="item-title">{{ data5[0].entityName }}</h2>
            <p class="item-description" v-if="data5[0].entityExplain">
              {{ data5[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data5[0].entityAd"></div>
            <h2 class="item-title">{{ data6[0].entityName }}</h2>
            <p class="item-description" v-if="data6[0].entityExplain">
              {{ data6[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data6[0].entityAd"></div>

            <h2 class="item-title">{{ data10[0].entityName }}</h2>
            <p class="item-description" v-if="data10[0].entityExplain">
              {{ data10[0].entityExplain }}
            </p>
          </div>
        </article>

        <article class="result-article">
          <div class="result-item">
            <h2 class="item-title">{{ data7[0].entityName }}</h2>
            <p class="item-description" v-if="data7[0].entityExplain">
              {{ data7[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data7[0].entityAd"></div>
            <h2 class="item-title">{{ data8[0].entityName }}</h2>
            <p class="item-description" v-if="data8[0].entityExplain">
              {{ data8[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data8[0].entityAd"></div>

            <h2 class="item-title">{{ data11[0].entityName }}</h2>
            <p class="item-description" v-if="data11[0].entityExplain">
              {{ data11[0].entityExplain }}
            </p>
          </div>
        </article>

        <article class="result-article">
          <div class="result-item">
            <h2 class="item-title">{{ data12[0].entityName }}</h2>
            <p class="item-description" v-if="data12[0].entityExplain">
              {{ data12[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data12[0].entityAd"></div>

            <!-- <div class="item-suggestions2" v-html="data11[0].entityAd"></div> -->
          </div>
        </article>

        <article class="result-article">
          <div class="result-item">
            <h2 class="item-title">{{ data13[0].entityName }}</h2>
            <p class="item-description" v-if="data13[0].entityExplain">
              {{ data13[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data13[0].entityAd"></div>
          </div>
        </article>

        <article class="result-article">
          <div class="result-item">
            <h2 class="item-title">{{ data14[0].entityName }}</h2>
            <p class="item-description" v-if="data14[0].entityExplain">
              {{ data14[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data14[0].entityAd"></div>
          </div>
        </article>
      </section>

      <button class="savabtn" @click="saveAsImage">保存报告</button>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>
import RangeBar from "../zhuoyue/component/RangeBar.vue";
import axios from "axios"; // 引入 axios
import html2canvas from "html2canvas";
import { Toast } from "vant";

export default {
  components: {
    RangeBar,
  },
  data() {
    return {
      userId: "",
      userName: "",
      addTime: "",
      data0: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      data7: [],
      data8: [],
      data9: [],
      data10: [],
      data11: [],
      data12: [],
      data13: [],
      data14: [],
      mobile: "",
      sleepQualityReport: {},
      workPressureReport: {},
      lifePressureReport: {},
      healthByMyselfReport: {},
      exerciseHabitReport: {},
      results: [
        {
          title: "总分",
          score: 0,
          maxScore: 100,
          segments: [
            { color: "qiancheng", flex: 60 },
            { color: "cyan", flex: 20 },
            { color: "green", flex: 20 },
          ],
          labels: ["0", "20", "40", "60", "80", "100"],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
    };
  },
  methods: {
    saveAsImage() {
      this.$nextTick(() => {
        const container = this.$refs.container;
        if (!container) {
          console.error("未找到 container 元素！");
          return;
        }

        // 获取整个容器的宽高
        const containerHeight = container.scrollHeight;
        const containerWidth = container.scrollWidth;

        // 调用 html2canvas 截图
        html2canvas(container, {
          useCORS: true, // 支持跨域
          scale: 2, // 提高清晰度
          width: containerWidth,
          height: containerHeight,
          windowWidth: containerWidth,
          windowHeight: containerHeight,
          scrollX: 0,
          scrollY: 0,
        })
          .then((canvas) => {
            const imageURL = canvas.toDataURL("image/png");
            const isWeChat = /MicroMessenger/i.test(navigator.userAgent);
            const isAndroid = /Android/i.test(navigator.userAgent);

            if (isWeChat && isAndroid) {
              Toast("请点击右上角保存图片，或在外部浏览器作答");
            } else {
              // 非微信安卓直接下载
              const link = document.createElement("a");
              link.href = imageURL;
              link.download = "个人报告.png";
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          })
          .catch((error) => {
            console.error("截图失败:", error);
          });
      });
    },
    fetchData() {
      // 显示加载中动画
      Toast.loading({
        duration: 0, // 设置为 0，确保手动关闭
        message: "加载中...",
        forbidClick: true, // 禁止点击
      });

      const voteLogId = this.voteLogId;
      axios
        .get(`https://assess.eapchina.net/getReport892`, {
          // .get(`http://192.168.1.217:8081/assess/getReport892`, {
          params: { voteLogId },
        })
        .then((response) => {
          // 请求成功后处理数据
          const res = response.data;
          this.data0 = res.data[0].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data1 = res.data[1].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data2 = res.data[2].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data3 = res.data[3].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data4 = res.data[4].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data5 = res.data[5].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data6 = res.data[6].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data7 = res.data[7].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data8 = res.data[8].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data9 = res.data[9].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data10 = res.data[10].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data11 = res.data[11].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data12 = res.data[12].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data13 = res.data[13].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));
          this.data14 = res.data[14].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br><br>"),
          }));

          // 用户数据处理
          this.addTime = res.addTime;
          this.userName = res.userName;

          // 成功后关闭加载动画
          Toast.clear();
        })
        .catch((error) => {
          // 捕获请求错误
          console.error("请求发生错误：", error);

          // 错误时关闭加载动画
          Toast.clear();
          Toast.fail("加载失败，请稍后重试");
        });
    },
  },
  created() {
    console.log(this.$route.query.voteLogId);
    this.voteLogId = this.$route.query.voteLogId;
    this.fetchData(); // 在组件挂载时调用请求
  },
};
</script>

<style scoped>
/* 容器样式 */
.container {
  max-width: 3.84rem; /* 最大宽度 */
  margin: 0 auto; /* 水平居中 */
  height: 100%; /* 高度自动根据内容适配 */
  display: flex;
  flex-direction: column; /* 保持子元素垂直排列 */
  box-sizing: border-box;
}

/* 顶部图片区域 */
.topimg {
  background-image: url("https://lesson.iapeap.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20250306101626.jpg");
  background-repeat: no-repeat; /* 避免图片重复 */
  background-size: 100% 100%;
  max-width: 100%; /* 宽度填满容器 */
  min-height: 400px;
}
.toptile {
  font-size: 30px;
  position: relative;
  top: 70px;
  /* 字体加粗 */
  font-weight: bold;
  left: 15px;
}
.toptile2 {
  font-size: 30px;
  position: relative;
  top: 100px;
  font-weight: bold;
  left: 15px;
}
.usermsg {
  position: relative;
  top: 190px;
  left: 20px;
  font-size: 14px;
  line-height: 25px;
}

/* 内容区域 */
.content {
  width: 100%;
  padding: 0 24px; /* 内容内边距 */
  background-color: #f9f9f9;
  background-image: url("https://lesson.iapeap.com/images/cp/2.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 8px; /* 圆角 */
  box-sizing: border-box;
  flex: 1; /* 占据剩余空间 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 底部图片区域 */
.footer {
  background-image: url("https://lesson.iapeap.com/images/cp/3.jpg");
  background-repeat: no-repeat; /* 避免图片重复 */
  background-size: 100% 100%;
  max-width: 100%; /* 宽度填满容器 */
  min-height: 224px;
}

/* 移动端自适应 */
@media (max-width: 3.84rem) {
  .content {
    border-radius: 0; /* 移动端去掉圆角 */
    padding: 16px; /* 减少内边距 */
  }
}
.report-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.report-date {
  font-size: 14px;
  text-align: center;
  color: #666666;
  margin-bottom: 30px;
}

/* 报告区块 */
.report-section {
  margin-bottom: 40px;
}

.section-title {
  background-color: #57c176;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  padding: 0 15px;
  margin-bottom: 20px;
}

.section-description {
  font-size: 16px;
  color: #444444;
  margin-bottom: 15px;
}

.section-list {
  /* margin-left: 20px; */
  color: #555555;
  font-size: 14px;
  line-height: 1.8;
  text-indent: 20px;
}

/* 测评结果 */
.result-article {
  margin-bottom: 30px;
}

.article-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.result-item {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  background: #f9fdfb;
  border: 1px solid #e0f2eb;
  border-radius: 8px;
}

.item-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.item-description {
  font-size: 14px;
  color: #555555;
  margin-bottom: 10px;
  line-height: 25px;
  text-indent: 20px;
}

.item-suggestions {
  list-style-type: disc;
  color: #666666;
  font-size: 14px;
  line-height: 1.6;
  background-color: #daf6e8;
  box-sizing: border-box;
  border-radius: 20px;
}
.item-suggestions2 {
  list-style-type: disc;
  color: #666666;
  font-size: 14px;
  line-height: 1.6;
  background-color: #daf6e8;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px;
  /* text-indent: 20px; */
}
.item-suggestionstitle {
  padding: 8px 15px;
  border-radius: 20px 20px 0 0;
  /* font-size: 12px; */
  /* text-align: center; */

  background-color: #adddc3;
}
.item-suggestionscontent {
  padding: 10px 15px;
}
.range-bar {
  margin: 15px 0;
  position: relative;
  width: 100%;
}

.range-bar-labels {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.range-bar-track {
  position: relative;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
}

.range-bar-segment {
  flex: 1;
  height: 100%;
}

.segment-low {
  background-color: #f85a3e;
}

.segment-medium {
  background-color: #ffa41b;
}

.segment-high {
  background-color: #57d9a3;
}

.range-bar-indicator {
  position: absolute;
  top: -6px;
  transform: translateX(-50%);
  height: 24px;
  width: 24px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.savabtn {
  padding: 10px 20px;
  background-color: skyblue;
  border-radius: 20px;
  border: 0;
  color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
